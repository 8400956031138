/* bootstrap import and custom colors */
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';

$theme-colors: (
  'primary': #0066b2,
  'danger': #f05a72,
  'secondary': #58585b,
  'success': #00b5ac,
  'warning': #fbaf17,
  'info': #00b5ac,
);

.gray {
  color: #58585b;
}

@import 'bootstrap/scss/bootstrap';
/*@import "~bootstrap/dist/css/bootstrap.min.css";*/

@import '~font-awesome/css/font-awesome.css';
@import '~devextreme/dist/css/dx.common.css';
@import '~devextreme/dist/css/dx.light.css';

.dx-loadpanel-wrapper {
  z-index: 2000 !important;
}

.dx-datagrid .dx-error-row .dx-error-message {
  white-space: pre-wrap;
}
